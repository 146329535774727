import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase'; // import your Firebase config here
import { collection, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';

const Patient = () => {
  const [showForm, setShowForm] = useState(false);
  const [showList, setShowList] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track if we are editing a patient
  const [editPatientId, setEditPatientId] = useState(null); // Store the patient ID being edited
  const [showHospitalField, setShowHospitalField] = useState(false); // To toggle hospital field

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    gp: '',
    gpmobile: '',
    status: '', // New status field
    hospital: '', // New hospital field (only displayed if status is Admit)
    dischargeDate: '' // New discharge date field
  });

  const [patients, setPatients] = useState([]);

  // Fetch data from Firestore
  const fetchPatients = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Patient'));
      const patientList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPatients(patientList);
    } catch (error) {
      console.error('Error fetching patient data: ', error);
    }
  };

  useEffect(() => {
    if (showList) {
      fetchPatients();
    }
  }, [showList]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handling status radio buttons
    if (type === "radio" && name === "status") {
      setFormData({
        ...formData,
        status: value,
        dischargeDate: value === 'Discharge' ? getCurrentDate() : '',
        hospital: value === 'Admit' ? formData.hospital : '', // Clear hospital field if not Admit
      });
      setShowHospitalField(value === 'Admit'); // Show hospital field only if Admit is selected
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Function to get the current date in 'dd-mm-yyyy' format
  const getCurrentDate = () => {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = now.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if (isEditing && editPatientId) {
        // Update existing patient
        const patientDocRef = doc(db, 'Patient', editPatientId);
        await updateDoc(patientDocRef, formData);
        alert('Patient updated successfully!');
      } else {
        // Add new patient
        await addDoc(collection(db, 'Patient'), formData);
        alert('Patient added successfully!');
      }
  
      setShowForm(false); // close the form after submission
      setIsEditing(false); // reset editing state
      setFormData({
        name: '',
        mobile: '',
        gp: '',
        gpmobile: '',
        status: '',
        hospital: '',
        dischargeDate: '' // Reset discharge date
      }); // reset the form fields
  
      fetchPatients(); // refresh patient list after submission
    } catch (err) {
      console.error('Error adding/updating patient: ', err);
      alert('Error adding/updating patient.');
    }
  };

  const handleEditClick = (patient) => {
    setFormData({
      name: patient.name,
      mobile: patient.mobile,
      gp: patient.gp,
      gpmobile: patient.gpmobile,
      status: patient.status || '',
      hospital: patient.hospital || '',
      dischargeDate: patient.dischargeDate || '' // Set discharge date if available
    });
    setEditPatientId(patient.id); // Store the ID of the patient being edited
    setIsEditing(true); // Set editing mode
    setShowForm(true); // Show the form for editing
    setShowList(false); // Close the list while editing
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Patient Management</h1>

      <div className="space-x-4">
        <button 
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={() => {
            setShowForm(!showForm);
            setShowList(false); // close the list when form is opened
            setIsEditing(false); // Reset editing state when adding a new patient
            setFormData({
              name: '',
              mobile: '',
              gp: '',
              gpmobile: '',
              status: '',
              hospital: '',
              dischargeDate: '' // Reset discharge date
            }); // Reset form data when adding a new patient
          }}
        >
          {showForm ? 'Close Form' : isEditing ? 'Edit Patient' : 'Add Patient'}
        </button>
        
        <button 
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={() => {
            setShowList(!showList);
            setShowForm(false); // close the form when list is opened
          }}
        >
          {showList ? 'Close List' : 'Patient List'}
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Patient Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Mobile Number:</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">General Practitioner (GP):</label>
            <input
              type="text"
              name="gp"
              value={formData.gp}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">GP Mobile Number:</label>
            <input
              type="text"
              name="gpmobile"
              value={formData.gpmobile}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Status:</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="status"
                  value="Admit"
                  checked={formData.status === 'Admit'}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                Admit
              </label>

              <label>
                <input
                  type="radio"
                  name="status"
                  value="Discharge"
                  checked={formData.status === 'Discharge'}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                Discharge
              </label>
            </div>
          </div>

          {showHospitalField && (
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">Hospital:</label>
              <input
                type="text"
                name="hospital"
                value={formData.hospital}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
          )}

          {formData.status === 'Discharge' && (
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">Discharge Date:</label>
              <input
                type="text"
                name="dischargeDate"
                value={formData.dischargeDate}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                readOnly // Discharge date is set automatically
              />
            </div>
          )}

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            {isEditing ? 'Update Patient' : 'Add Patient'}
          </button>
        </form>
      )}

      {showList && (
        <div className="mt-4">
          <h2 className="text-xl font-bold mb-2">Patient List</h2>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2">Name</th>
                <th className="py-2">Mobile</th>
                <th className="py-2">Status</th>
                <th className="py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient) => (
                <tr key={patient.id}>
                  <td className="border px-4 py-2">{patient.name}</td>
                  <td className="border px-4 py-2">{patient.mobile}</td>
                  <td className="border px-4 py-2">{patient.status}</td>
                  <td className="border px-4 py-2">
                    <button
                      className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                      onClick={() => handleEditClick(patient)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Patient;
