import React from 'react'
import Surgery from './Surgery'
import Hospital from './Hospital'
import ForDoctors from './ForDoctors'
import Lab from './Lab'


const Home = () => {
  return (
    <div>
      <Hospital/>

      <Surgery/>
      
      <ForDoctors/>

      <Lab/>

      
    </div>
  )
}

export default Home
