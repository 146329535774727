import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import UserDashboard from '../dashboard/UserDashboard';
import AdminDashboard from '../dashboard/AdminDashboard';
import AdminMain from '../dashboard/dpages/AdminMain';
import Users from '../dashboard/dpages/Users';
import EditUser from '../dashboard/dpages/EditUser';
import HealthCard from '../dashboard/dpages/HealthCard';
import DoctorsData from '../dashboard/dpages/DoctorsData';
import DoctorDetails from '../dashboard/dpages/DoctorDetails';
import ErrorPage from '../components/ErrorPage'; // Import the ErrorPage component
import Booking from '../dashboard/dpages/Booking';
import Surgery from '../pages/Surgery';
import Enquiry from '../dashboard/dpages/Enquiry';
import Partner from '../dashboard/dpages/Partner';
import EditPartner from '../dashboard/dpages/EditPartner';
import Advertisement from '../dashboard/dpages/Advertisement';
import LabTest from '../dashboard/dpages/LabTest';
import HospitalData from '../dashboard/dpages/HospitalData';
import ForDoctors from '../pages/ForDoctors';
import DoctorProfile from '../pages/DoctorProfile';
import DoctorDashboard from '../dashboard/DoctorDashboard';
import DoctorAdminMain from '../dashboard/doctordash/DoctorAdminMain';
import Profile from '../dashboard/doctordash/Profile';
import Patient from '../dashboard/dpages/Patient';
import Lab from '../pages/Lab';
import LabDetails from '../dashboard/dpages/LabDetails';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />, // Add the errorElement here
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "surgery",
        element: <Surgery />,
      },
     
      {
        path: "user-dashboard",
        element: <UserDashboard />,
      },
      {
        path: "for-doctor",
        element: <ForDoctors />,
      },
      {
        path: "lab",
        element: <Lab />,
      },
      {
        path: "doctor-profile",
        element: <DoctorProfile />,
      },
      {
        path: "doctor-dashboard",
        element: <DoctorDashboard />,
        errorElement: <ErrorPage/>,
        children : [
          {
            path : "",
            element :<DoctorAdminMain/>
          },
          {
            path: "profile",
            element : <Profile/>
          },
        ]
      },
     
      {
        path: "admin-dashboard",
        element: <AdminDashboard />,
        errorElement: <ErrorPage />, // Add errorElement here too
        children: [
          {
            path: "",
            element: <AdminMain />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "edit-user/:id",
            element: <EditUser />,
          },
          {
            path: "edit-partner/:id",
            element: <EditPartner />,
          },
          {
            path: "health-card",
            element: <HealthCard />,
          },
          {
            path: "doctor-data",
            element: <DoctorsData />,
          },
          {
            path: "doctor-details/:id",
            element: <DoctorDetails />, // The component that shows the detailed information and CRUD operations
          },
          {
            path: "booking",
            element: <Booking />,
          },
          {
            path: "enquiry",
            element: <Enquiry />,
          },
          {
            path: "partner",
            element: <Partner />,
          },
          {
            path: "advertisement",
            element: <Advertisement />,
          },
          {
            path: "lab-test",
            element: <LabTest />,
          },
          {
            path: "hospital-data",
            element: <HospitalData/>,
          },
          {
            path: "patient",
            element: <Patient/>,
          },
          {
            path: "lab-details",
            element: <LabDetails/>,
          },
          
        ],
      },
    ],
  },
]);

export default router;
