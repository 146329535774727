import React from 'react'

const Lab = () => {
  return (
    <div>
      Lab 
    </div>
  )
}

export default Lab
