import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { FaHome, FaUsers, FaIdCardAlt, FaBars, FaUserShield, FaBookMedical, FaLock, FaUserInjured  } from 'react-icons/fa'; // Importing some FontAwesome icons
import AdminMain from './dpages/AdminMain';

const AdminDashboard = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showUserSubLinks, setShowUserSubLinks] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false); // State for collapsing sidebar
  const location = useLocation();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const checkUserRole = async () => {
      if (user) {
        const db = getDatabase();
        const userRef = ref(db, `UserProfile/${user.uid}`);
        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setIsAuthorized(userData.role === 'admin');
          } else {
            setIsAuthorized(false);
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
          setIsAuthorized(false);
        }
      } else {
        setIsAuthorized(false);
      }
      setLoading(false);
    };

    checkUserRole();
  }, [user]);

  const isMainPage = location.pathname === '/admin-dashboard' || location.pathname === '/admin-dashboard/';
  const isUsersPage = location.pathname.startsWith('/admin-dashboard/users');

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='min-h-[calc(100vh-120px)] md:flex hidden'>
      <aside className={`bg-white dark:bg-gray-900 ${isCollapsed ? 'w-16' : 'w-60'} transition-all duration-300 min-h-full shadow-md`}>
        <div className='flex items-center justify-between p-4'>
          <span className={`${isCollapsed ? 'hidden' : 'block'} text-xl font-bold text-blue-500`}>Admin Dashboard</span>
          <button onClick={() => setIsCollapsed(!isCollapsed)}>
            <FaBars className='text-xl text-blue-500' />
          </button>
        </div>

        <nav className='mt-10'>
          <Link to="" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaHome className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Home</span>
          </Link>

          <div className='relative'>
            <button
              onClick={() => setShowUserSubLinks(!showUserSubLinks)}
              className='flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-blue-500 hover:text-white'
            >
              <FaUsers className='text-xl' />
              <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Users</span>
            </button>
            {showUserSubLinks && (
              <div className={`mt-2 space-y-2 ${isCollapsed ? 'hidden' : 'block'}`}>
                <Link to="partner" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                  Partner
                </Link>
                <Link to="users" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                  User
                </Link>
                <Link to="manager" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                  Manager
                </Link>
                <Link to="app-user" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                  App User
                </Link>
              </div>
            )}
          </div>

          <Link to="health-card" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaIdCardAlt className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Health Card</span>
          </Link>

          <Link to="doctor-data" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserShield className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Doctor</span>
          </Link>

          <Link to="booking" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaBookMedical className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Booking</span>
          </Link>
          <Link to="enquiry" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaLock className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Enquiry</span>
          </Link>
          <Link to="advertisement" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaLock className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Advertisment</span>
          </Link>

          <Link to="lab-test" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaLock className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Lab Test</span>
          </Link>
          <Link to="hospital-data" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaLock className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Hospital</span>
          </Link>
          <Link to="patient" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserInjured  className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Patient</span>
          </Link>
          <Link to="lab-details" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserInjured  className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Lab Details</span>
          </Link>
        </nav>
      </aside>

      

      <main className='w-full h-full p-2'>
        {isMainPage ? (
          <AdminMain />
        ) : isAuthorized ? (
          <Outlet />
        ) : (
          <div className="text-red-500 text-center">
            You are not authorized to see this page. Contact Admin to resolve.
          </div>
        )}
      </main>
    </div>
  );
};

export default AdminDashboard;
