import React from 'react'

const LabDetails = () => {
  return (
    <div>
      Lab Details
    </div>
  )
}

export default LabDetails
